:root {
  --default-background: #282c34;
  --default-title: #181a1f;
  --default-border: #181a1f;
  --default-color: white;

  --state-good: darkgreen;
  --state-alert: #AF7500;
  --state-alarm: darkred;

  --color-gold: #a78349;
}
$responsive-width: 1300px;
