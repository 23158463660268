.infos
{
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    background-color: var(--default-background);

    &__text
    {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 2rem;
        color: var(--default-color);
    }
    &__html
    {
        margin: 0 auto;
    }
    &__logo
    {
        position: absolute;
        bottom: 0px;
        left: 0px;
        z-index: 500;
        cursor: pointer;
        width: 25%;
        min-width: 110px;
        max-width: 180px;
        @media screen and (max-width: 150px)
        {
            min-width: 30px;
            width: 30px;
        }
        .logo1
        {
            margin-bottom: -3px;
            width: 100%;
            @media screen and (max-width: 150px)
            {
                display: none;
            }
        }
        .logo2
        {
            width: 30px;
            margin-bottom: -3px;
            @media screen and (min-width: 150px)
            {
                display: none;
            }
        }
    }
}
