@import './constants';

:root {
	font-family: 'Lato', Inter, system-ui, Helvetica, Arial, sans-serif;
	background-color: var(--default-background);
	font-synthesis: none;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  color: white;
}

.app
{
	&__maincont
	{
		display: flex;
		flex-direction: row;
		font-size: 80%;

		&__servers
		{
			flex: 1 1 auto;
		}
		&__program
		{
			flex: 0 0 auto;
			@media screen and (max-width: $responsive-width)
			{
				flex: 1 1 auto;
				width: 100%;
				margin: 0 auto;
			}
		}

		@media screen and (max-width: $responsive-width)
		{
			flex-direction: column-reverse;
		}
	}
}