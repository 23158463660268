.loadercont
{
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 100;
	background-color: var(--default-background);
}
.loader {
  margin-top: calc(100vh / 2 - 50px);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}