.mapcont
{
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    &__map
    {
        position: relative;
        display: block;
        font-size: 1.2rem;
        color: var(--default-color);
        width: 100%;
        height: 100%;
    }
    &__logo
    {
        position: absolute;
        bottom: 0px;
        left: 0px;
        z-index: 500;
        cursor: pointer;
        width: 25%;
        min-width: 110px;
        max-width: 180px;
        @media screen and (max-width: 150px)
        {
            min-width: 30px;
            width: 30px;
        }
        .logo1
        {
            margin-bottom: -3px;
            width: 100%;
            @media screen and (max-width: 150px)
            {
                display: none;
            }
        }
        .logo2
        {
            width: 30px;
            margin-bottom: -3px;
            @media screen and (min-width: 150px)
            {
                display: none;
            }
        }
    }
}
.mc-cont {
    position:relative;
}
.mc-circle {
    position:absolute;
    left: -3px;
    top:-3px;
    width: 6px;
    height: 6px;
    background-color: black;
    border: 1px solid white;
    border-radius: 4px;
    // display: none;
}
.mc-bigrect {
    position:absolute;
    left: 0px;
    top: -28px;
    transform: translateX(-50%);
}
.mc-athlete-card {
    background-color: #fff;
    transform: skew(-24deg, 0deg);
    width:fit-content;
    display: flex;
    border: 1px solid #02162d;
    border-left:2px solid #02162d;
    border-right:2px solid #02162d;
    border-radius:3px;
}
  
.mc-athlete-info {
    display: flex;
    font-size: 11px;
    line-height: 11px;
    font-weight: 800;
}
  
.mc-athlete-name {
    transform: skew(30deg, 0deg);
    color: var(--deep-navy-base, #02162d);
    text-align: left;
    font-family: 'transducer-extended', 'Dela Gothic One';
    text-transform:uppercase;
    padding: 3px 8px;
    white-space: nowrap;
}
  
.mc-athlete-metric {
    background: #00FFFF;
    border-top-right-radius: 3px;
    border-bottom-right-radius:3px
}
  
.mc-metric-text {
    transform: skew(30deg, 0deg);
    color: var(--deep-navy-base, #02162d);
    text-align: left;
    font-family: 'transducer-extended', 'Dela Gothic One' !important;
    text-transform:uppercase;
    padding: 3px 8px;
}
  
.mc-triangle {
    position: absolute;
    bottom: -1px;
    left: calc(50% + 1px);
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #000;
}
  